<!-- 问题反馈 -->
<template>
  <div class="page-main">
    <header class="page-header">
      <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
      <div class="header-content">问题反馈</div>
    </header>
    <section class="page-info">
      <div class="info-attention">
        <span style="width: 100%; display: inline-block;">问题反馈成功！</span>
        <span style="width: 100%; display: inline-block; font-size: 15px; margin-top: 15px;">感谢您提出的宝贵意见，祝您生活愉快，谢谢！</span>
      </div>
    </section>
  </div>
</template>
  
<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { Toast } from 'vant'

export default {
  name: "complete",
  components: {},
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      document.title = '问题反馈';
    });

    const data = reactive({

    });

    
    return {
      ...toRefs(data),
    };
  },
  methods: {}
};
</script>
  
<style scoped lang="scss">
.page-main {
  width: 100%;

  .page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;

    .header-content {
      text-align: center;
      font-size: 18px;
      padding-right: 20px;
      flex: 1;
      font-weight: 700;
    }
  }

  .page-info {
    background-color: #fff;
    min-height: 300px;
    margin-bottom: 10px;
    text-align: center;

    .info-attention {
      background: linear-gradient(to right, #9F88FF, #E8CCFF);
      padding: 30px 20px 20px 20px;
      color: #fff;
      font-size: 20px;
      line-height: 25px;
    }
  }
}
</style>
  